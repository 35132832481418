<template>
  <div class="bg-404"></div>
</template>
<style lang="less" scoped>
.bg-404 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: url("../assets/images/404.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
